import React from 'react';
import { Modal, } from 'semantic-ui-react';

const Confirm = (props) => {
  
  return (
   
      <Modal size="mini" 
      open={props.open} 
      //onClose={onCancel}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      style={{top:'auto'}} // for positioning of modal at center of view port
         >
       
        <Modal.Content className='border'>
        <div className= 'display-center'> <h6 className='color-indigo'>{props.header}</h6></div>
          <div className= 'display-center'>
          
          <span
            className="btntask" style={{background:props.color}}
              onClick={props.onConfirm}

          >
            {props.action}
          </span>
          <span
            className="btntask" 
              onClick={props.onCancel}

          >
            Cancel
          </span>
          </div>
        </Modal.Content>
      </Modal>
    
  );
}



export default Confirm;
