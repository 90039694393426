import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import format from "date-fns/format";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {fetchUser,resetUser} from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";
import { openPopup } from "../../actions/popupAction";

import {
  Divider,
  Container,
  Modal,
  Segment,
} from "semantic-ui-react";
import Confirm_2 from "../common/Confirm_2";
import EditEmailUserId from './EditEmailUserId'


class UserDetail extends Component {
  state = {
    id: "",
    open: false,
    editModalOpen:false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchUser(id);
  }

  handleOpenModal = (url) => {   
    this.props.openModal("ImageZoomModal" , {imageURL:url});
  };
  handleOpenPopup = (ph , col) => {
    const message= `${ph} Mobile Number Copied` 
    this.props.openPopup("CopyPhoneFixedPopup" , {message:message, color:col,});
  };
  componentWillUnmount() {
      this.props.resetUser();
    }

    //^^^^^^^^^^^^^^^
show = (id ) => {

  this.setState({ open: true, id: id, });
};

//******************* */
handleConfirm = async () => {
  
//************
  try {
      
    //^^^^^^^^^^^^^^^^^
    const res = await axios.post(`/api/ResetUserPassword` , {id:this.state.id});

      if(res.data.msg ==='success'){
        this.setState({ open: false });
        toastr.success('Password Changed Successfully')
        }
        
    }catch (error) {
      if(error && error.response && error.response.status==401){
        this.props.openModal('LoginModal')
        toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
        toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
//**********
}

handleCancel = () => this.setState({ open: false });

//**********************************

handleEditModalOpen = () => this.setState({ editModalOpen:true,  })
handleEditModalClose = () =>{
   this.setState({ editModalOpen:false })
      }  //
handleEditModalCloseSubmit = () =>{
  const { id } = this.props.match.params;
  this.props.fetchUser(id);
        this.setState({ editModalOpen:false })
}
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
//**********************************

  render() {

    if (!this.props.user) {
      return (
        <div><span style={{marginLeft: '200px',fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
    const {
      userType,
      uname,
      uimage,
      uemail,
      uphone1,
      uphone2,
      uwhatsapp,
      shopName,
      address,
      projectName,
      locality,
      city,
      state,
      pincode,
      // userSubscriptionStartOn,
      // userSubscriptionExpireOn,
      userStatus,
userId,
createdAt,
      _id
    } = this.props.user;

    //******************
    const renderPassReSetButton = () =>
      this.props.userA !== null &&
      (this.props.userA.leadDelete === "Yes" ||
        this.props.userA.userType === "admin") ? (
        <span
        onClick={() => this.show(_id)}
        className='icon-button1 font-16B border-skyblue-2 pxy-5-10 mr-05 '
        >
        Reset Password
        </span>

      ) : (
        <div></div>
      );
      //^^^^^^^^^^^
      //******************
    const renderUserIDButton = () =>
    this.props.userA !== null &&
    (this.props.userA.leadDelete === "Yes" ||
      this.props.userA.userType === "admin") ? (
      <span
      onClick={()=>this.handleEditModalOpen()}
      className='icon-button1 font-16B border-skyblue-2 pxy-5-10 mr-05 '
      >
      Edit userId/email
      </span>

    ) : (
      <div></div>
    );
    //^^^^^^^^^^^
    const renderEditButton = () =>
    
          (  <span
            className='icon-button1 font-16B border-skyblue-2 pxy-5-10 mr-05 '
            >
            <Link to={`/user/UserEdit/${_id}`} >
            Edit
            </Link>

            </span>
      ) 

      //^^^^^^^^^^^
    const renderTotalEntriesButton = () =>
    
    (  <span
      className='icon-button1 font-16B border-skyblue-2 pxy-5-10 mr-05 '
      >
      <Link to={`/entries/TotalEntriesSingleCompany/${_id}`} >
      Total Entries
      </Link>

      </span>
) 
      //***** */
const daysAgo = Math.round(
      (Date.now() - Date.parse(createdAt)) / (24 * 60 * 60 * 1000)
    );
      const { open,} = this.state;
      const header =`Do You  Want To Reset Password to 12345 ?`
      //******************
    return (
      <Container text>
        <Confirm_2
        header={header}
        action={`Reset Password`}
        color={'teal'}
        size="mini"
        open={open}
        onCancel={this.handleCancel}
        onConfirm={this.handleConfirm}
      />
      {/* EditUser Modal*/}

      <Modal
      open={this.state.editModalOpen}
      onClose={this.handleEditModalClose}
      basic
      closeOnEscape={false}
      closeOnDimmerClick={false}
      // size='small'
    >

      <Modal.Content>
      <EditEmailUserId
      id={_id}
      userId={userId}
      uemail={uemail}
      uname={uname}
      handleEditModalCloseSubmit={this.handleEditModalCloseSubmit}
      handleEditModalClose= {this.handleEditModalClose}
       />

      </Modal.Content>

    </Modal>
    
      {/**/}
      <div className='margin-auto-H' >
      <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
        {/*  */}
        {uname}
     {/*  */}
     </div>
     </div>
     {/**/}

        <Segment attached>
        <div>
          {/*  */}
          
          {renderTotalEntriesButton()}
          {renderEditButton()}
          {renderPassReSetButton()}
          {renderUserIDButton()}
          
          <div className="disflex-paginator">
          <img 
              className="icon2-button "
              onClick={() => this.handleOpenModal(uimage)}
              src={`${uimage}`} alt="profile image" 
              style={{width:"100px", height:"100px",}} />
          
          </div>
{/* */}

          <br />
          <Divider fitted />
          <div>
          <div >
          {address && (
            <div className='row'>
              <div className='side'>
              {/* <Image
                floated="left"
                size="tiny"
                src={`${uimage}`}
              /> */}
              Address
              </div>
              <div className='main'>
               <div style={{color:'blue'}}><b>{shopName}</b></div>
               <b>{address}{' '}{projectName}{' '} {locality} {' '}{city}{' '}{state}{' '}{pincode}</b>
              </div>

            </div>
          )}
        </div>


        <div >
        {uphone1 && (
        <div className='row'>
          <div className='side'>
          <b>Phone1</b>
          </div>
          <div className='main'>
          <b>{uphone1}</b>
          {' '} <span className='nodisplay-tab'>
             <CopyToClipboard text={uphone1}  >
              <span className="icon-button"  onClick= {() => this.handleOpenPopup(uphone1,'blue' )}>
              <img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
              </span>
            </CopyToClipboard>
            </span>
          </div>

        </div>
        )}
        </div>

        <div >
        {uphone2 && (
        <div className='row'>
          <div className='side'>
          <b>Phone2</b>
          </div>
          <div className='main'>
          <b>{uphone2}</b>
          {' '} <span className='nodisplay-tab'>
        <CopyToClipboard text={uphone2}  >
        <span className="icon-button"  onClick= {() => this.handleOpenPopup(uphone2,'teal' )}>
        <img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>
            </CopyToClipboard>
            </span>
          </div>

        </div>
        )}
        </div>


        <div >
        {uemail && (
          <div className='row'>
            <div className='side'>
              <b>Email</b>
            </div>
            <div className='main'>
              <b>{uemail}</b>
            </div>

          </div>
        )}
      </div>


      <div >
      {uwhatsapp && (
      <div className='row'>
        <div className='side'>
        <b>Whatsapp Id</b>
        </div>
        <div className='main'>
        <b>{uwhatsapp}</b>
        {' '} <span className='nodisplay-tab'>
           <CopyToClipboard text={uwhatsapp}  >
            <span className="icon-button"  onClick= {() => this.handleOpenPopup(uwhatsapp,'brown' )}>
            <img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>
          </CopyToClipboard>
          </span>
        </div>

      </div>
      )}
      </div>

      <div >
        {userType && (
          <div className='row'>
            <div className='side'>
              <b>UserType</b>
            </div>
            <div className='main'>
              <b>{userType}</b>
            </div>

          </div>
        )}
      </div>

      <div >
        {userStatus && (
          <div className='row'>
            <div className='side'>
              <b>User Status</b>
            </div>
            <div 
            className=" main" >
              <b
              className= {userStatus === 'inactive'? 'color-red':''} 
              >{userStatus}</b>
            </div>

          </div>
        )}
      </div>

<div>
          {createdAt && (
            <div className="row">
              <div className="side">
                <b>Created On</b>
              </div>
              <div className="main">
                <b>{format(new Date(createdAt), "dd-MM-yyyy")}</b>{" "}
                <b style={{ color: "blue" }}>({daysAgo} Days Ago )</b>
              </div>
            </div>
          )}
        </div>


          </div>

          </div>
        </Segment>
      </Container>
    );
  }
}



const mapStateToProps = ({ auth }) => {

  return {
    user: auth.userD,
    userA:auth.userA
  };
};

export default connect(
  mapStateToProps,
  {fetchUser,resetUser, openPopup,openModal}
)(UserDetail);
